/*------------- #ACCORDIONS --------------*/

.crumina-accordion {
  list-style: none;
  margin: 0;
  padding: 0;
}

.accordion-panel {
  position: relative;
  background-color: $white-color;
  border-radius: 50px;
  margin-bottom: 5px;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: auto;
    position: absolute;
    left: 65px;
    top: 20px;
    bottom: 20px;
    background-color: #cccccc;
    transition: all .3s ease;
  }
}

.accordion-heading {
  display: table;
  padding: 25px 30px;
  font-size: 18px;
  transition: all .3s ease;
  cursor: pointer;
  outline: none;
  width: 100%;
  background-color: transparent;

  span {
    display: table-cell;
    vertical-align: middle;
  }

  .title {
    color: $primary-color;
    text-align: left;
    transition: all .3s ease;
  }

  .icon {
    font-size: 20px;
    padding-right: 60px;

    i {
      color: $primary-color;
      transition: all .3s ease;
      transform: rotate(90deg);
    }
  }

  &.collapsed {
    background-color: transparent;
    box-shadow: none;

    .icon {
      i {
        transform: rotate(0);
        color: #cccccc;
      }
    }

    .title {
      color: $heading-color;
    }
  }
}

.panel-collapse.collapsing,
.panel-collapse.show {
  box-shadow: 9px 9px 9px 0 rgba(76,194,192, 0.2);
  border-radius: 0 0 50px 50px;
}

.crumina-accordion .panel-info {
  line-height: 1.3;
  padding: 0 20px 30px 100px;
  transition: all .3s ease;

  > * {
    float: none;
  }
}


/*=====================================================
                Transition
=======================================================*/

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

/*================= Responsive Mode ============*/

@media (max-width: 768px) {
  .accordion-heading .title {
    line-height: 1;
    font-size: 15px;
  }

  .crumina-accordion .panel-info {
    font-size: 13px;
  }

  .accordion-heading {
    padding: 15px 30px;
  }
}