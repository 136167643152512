/*------------- #INFO-BOX --------------*/


.info-boxes {
  .info-box--standard {
    margin-bottom: 80px;

    @media (max-width: 640px) {
      margin-bottom: 30px;
    }
  }
}

.info-box--standard {

    .info-box-image {
      float: left;
      margin-right: 30px;
      margin-bottom: 30px;

      @media (max-width: 360px) {
        float: none;
      }

      &.f-none {
        float: none;
      }

      img {
        height: 70px;
      }
    }

    .info-box-content {
      overflow: hidden;
      padding-top: 10px;

      .info-box-title {
        line-height: 1.1;
        margin-bottom: 10px;
        text-transform: capitalize;

        &.text-t-none {
          text-transform: none;
        }
      }

      .text {
        font-size: 16px;
        line-height: 1.5;
      }
    }
}






/*------------- #Info-box-standard-centered --------------*/

.info-box--standard-centered {
  text-align: center;
  margin-bottom: 30px;

  .info-box-image {
    margin-bottom: 25px;

    img {
      border-radius: 100%;
      transition: all .3s ease;
    }

  }

  .info-box-content {
    margin-bottom: 30px;

    .info-box-title {
      font-size: 24px;
      line-height: 1.1;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .text {
      font-size: 16px;
      line-height: 1.5;
      }
    }

  &:hover {

    .info-box-image {

      img {
        -webkit-box-shadow: 25px 20px 20px -15px rgba(0,0,0,0.3);
        -moz-box-shadow: 25px 20px 20px -15px rgba(0,0,0,0.3);
        box-shadow: 25px 20px 20px -15px rgba(0,0,0,0.3);
      }
    }
  }
  .btn:hover {
    background-color: $primary-color;
  }
}



/*------------- #Info-box-standard-bg --------------*/


.info-box--standard-bg {
  text-align: center;
  margin-top: 50px;

  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }

  .info-box-content {
    position: relative;
    background-color: $white-color;
    padding: 80px 40px 40px;

    .info-box-image {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }

    .info-box-title {
      font-size: 24px;
      line-height: 1.1;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .text {
      font-size: 16px;
      line-height: 1.5;
    }
  }
}



/*------------- Block with info-box --------------*/

.background-mountains {
  position: relative;
  overflow: hidden;

  .heading {
    margin-bottom: 76px;

    @media (max-width: 800px) {
      margin-bottom: 35px;
    }
  }

  .btn-block {
    margin-top: 50px;

    .btn {
      margin: 0 30px;

      @media (max-width: 480px) {
        margin: 0 0 30px;
      }
    }
  }

  .images {
    img {
      position: absolute;
      bottom: -100%;
      left: 0;
      opacity: 0;
      min-width: 100%;
    }
  }
}


/*------------- Info-box-modern --------------*/


.info-box--modern {
  position: relative;
  padding: 40px;
  background-color: $border-color;
  transition: all .3s ease;

  &:hover {
    background-color: $heading-color;

    .info-box-content {

      .info-box-title {
      color: $white-color;
      }

      .read-more {
        color: $primary-color;

        i {
          color: $primary-color;
        }
      }
    }
  }

  .info-box-image {
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;

    @media (max-width: 980px) {
      float: none;
    }
  }

  .info-box-content {
    overflow: hidden;

    .info-box-title {
      font-size: 30px;
      line-height: 1.1;
      margin-bottom: 15px;
    }

    .text {
      font-size: 16px;
      margin-bottom: 25px;
    }

    .read-more {
      font-size: 16px;
      color: #ccc;
      padding: 0;
      border-left: none;
      margin: 0;
      float: none;
      display: block;

      i {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
}
