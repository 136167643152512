/*------------- #POPUP-CART --------------*/


.cart-popup-wrap {

  position: absolute;
  top: 39px;
  right: -50px;
  z-index: 1;
  width: 330px;
  border-radius: 5px;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  display: none;
  z-index: 10;

  @media (max-width: 980px) {
    right: auto;
    left: 0;
  }

  @media (max-width: 800px) {
    right: 0;
    left: auto;
  }

  @media (max-width: 570px) {
    right: 0;
  }

  @media (max-width: 320px) {
    right: -60px;
  }

    .popup-cart {
      padding: 40px;
      background-color: #fff;

      .title-cart {
        font-size: 22px;
      }

      .subtitle {
        font-size: 16px;
        margin-bottom: 20px;
      }

      .btn {
        text-transform: capitalize;
        width: 100%;
        text-align: center;
      }

      .cart-product {
        .cart-product__item {
          display: table;
          padding: 20px 0;
          border-bottom: 2px solid $border-color;

          .product-del {
            width: 25px;
            height: 25px;
            position: relative;
            font-size: 25px;
            color: $secondary-color;
            z-index: 2;
            font-weight: 100;
            cursor: pointer;
            display: table-cell;
            vertical-align: middle;
          }

          img {
            float: left;
            margin-right: 20px;
            display: table-cell;
            vertical-align: middle;
          }

          .cart-product-content {
            display: table-cell;
            vertical-align: middle;

            .cart-product-title {
              font-size: 16px;
              line-height: 1;
            }

            .price {
              font-size: 14px;
            }
          }
        }
      }

    }

  .cart-total {
    padding: 30px 40px 40px;
    background-color: $border-color;
    overflow: hidden;
    width: 100%;

    .cart-total-text {
      margin-bottom: 30px;
      overflow: hidden;

      .title {
        text-transform: uppercase;
        font-size: 16px;
        float: left;
      }

      .total-price {
        color: $primary-color;
        font-size: 18px;
        float: right;
      }
    }

    .btn {
      width: 50%;
      border-radius: 50px 0 0 50px;
      float: left;

      &:last-child {
        border-radius: 0 50px 50px 0;
      }

      + .btn {
        margin-left: 0;
      }
    }
  }

  &.cart-with-product {
    width: 370px;

    @media (max-width: 360px) {
      width: 330px;
    }

    .popup-cart {
      padding: 40px 40px 0 40px;
    }
  }
}
