/*------------- #LOCAL-SEO --------------*/


.local-seo {
  img {
    position: relative;

    @media (max-width: 1024px) {
      width: 70%;
    }

    &:last-child {
      position: absolute;
      bottom: -120px;
      left: 50%;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);

      @media (max-width: 768px) {
        bottom: -55px;
      }

      @media (max-width: 320px) {
        bottom: -25px;
      }
    }

    &:nth-child(2) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}