/*------------- #NAVIGATION --------------*/


.navigation {
  margin: 20px auto;
  width: 100%;
  position: relative;
  max-width: 770px;
  text-align: center;

  a {
    position: relative;
    background: $white-color;
    display: inline-block;
    margin: 0 12px 0 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border: 2px solid transparent;
    text-transform: uppercase;
    color: $font-color;
    font-size: 20px;
    border-radius: 100%;
    transition: all .3s ease;

    @media (max-width: 640px) {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      margin: 0 8px 0 0;
    }

    @media (max-width: 460px) {
      width: 25px;
      height: 25px;
      line-height: 22px;
      font-size: 12px;
      margin: 0 8px 0 0;
    }

    &:hover {
      color: $primary-color;
    }

    &.current {
      border-color: $primary-color;
      background-color: transparent;
      color: $primary-color;
    }

    span {
      position: relative;
    }

    &.bg-border-color {
        background-color: #f7f9f9;

        &.current {
          background-color: transparent;
        }

    }

  }

  .next, .btn-next {
    position: relative;
    float: right;
  }

  .prev, .btn-prev {
    position: relative;
    float: left;
  }

}