/*------------- #VARIABLES --------------*/


// We use these as default colors throughout

$primary-color: #4cc2c0 !default;
$secondary-color: #f15b26 !default;
$heading-color: #2f2c2c !default;
$global-link-color: #2f2c2c !default;
$font-color: #acacac !default;


$white-color: #fff !default;
$border-color: #f7f9f9 !default;
$main-border-color: #ced3d3 !default;




// Socials color

$social-bg: #333 !default;
$faceb-color: #3b5998 !default;
$youtube-color: #e82018 !default;


$h1-fontsize: 48px !default;
$h2-fontsize: 42px !default;
$h3-fontsize: 36px !default;
$h4-fontsize: 30px !default;
$h5-fontsize: 24px !default;
$h6-fontsize: 18px !default;
$h-font-weight: 400 !default;

$heading-font-family: 'Varela Round', Arial, 'Helvetica Neue', Helvetica, sans-serif !default;
$p-font-size: 18px !default;

// We use these to control various global styles

$body-bg: #fff !default;
$body-font-size: 16px !default;
$body-font-color: #acacac !default;
$body-dark-font-color: #2f2c2c !default;
$body-font-family: 'Varela Round', Arial, 'Helvetica Neue', Helvetica, serif !default;
$body-font-weight: 400 !default;
$base-line-height: 1.5 !default;

$semibold-weight: 600 !default;

$border-round: 100% !default;



//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  570px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  640px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  768px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1170px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (640px) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (768px) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1170px) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;