/*------------- #TESTIMONIAL --------------*/


.testimonial-item {
  position: relative;

  .testimonial__thumb {
    background-color: #362f2d;
    border-radius: 0 100px 0 100px;
    padding: 50px 100px;

    @media (max-width: 480px) {
      padding: 70px 50px;
    }

    @media (max-width: 360px) {
      padding: 70px 20px;
    }
  }

  .testimonial-content {
    .text {
      color: $white-color;
      font-size: 24px;

      @media (max-width: 980px) {
        font-size: 20px;
      }

      @media (max-width: 640px) {
        font-size: 16px;
      }
    }

    .author {
      color: #fcd846;
      font-size: 18px;
      display: block;
    }

    .company {
      font-size: 14px;
      color: #797676;
      display: block;
    }

    .quote {
      position: absolute;
      right: 100px;
      bottom: 20px;

      @media (max-width: 980px) {
        right: 50px;
      }

      @media (max-width: 360px) {
        right: 0;
        bottom: 0;
      }

      i {
        font-size: 140px;
        color: #3b3432;
      }
    }
  }

  .avatar {
    position: absolute;
    background-image: url("../img/avatar-bg.png");
    width: 119px;
    height: 112px;
    line-height: 112px;
    text-align: center;
    top: -100px;
    left: -140px;

    @media (max-width: 640px) {
      left: -120px;
    }

    @media (max-width: 480px) {
      left: 80px;
      top: -130px;
    }

  }

  &.testimonial-arrow {
    padding: 50px 60px;
    background-color: #fff;
    position: relative;

    @media (max-width: 980px) {
      padding: 30px;
    }

    .testimonial-text {
      font-size: 24px;
      color: $heading-color;
      margin-bottom: 25px;
      position: relative;
      z-index: 20;

      @media (max-width: 980px) {
        font-size: 18px;
        line-height: 1.3;
      }
    }

    .author-info-wrap {

      .testimonial-img-author {
        float: left;
        margin-right: 30px;

        &.round {
          border-radius: 100%;
          overflow: hidden;
        }
      }
    }

    .quote {
      position: absolute;
      bottom: 15px;
      right: 60px;

      i {
        font-size: 140px;
        color: $border-color;
      }
    }

    &:before {
    content:"";
    position:absolute;
    bottom: 0;
    right: 0;
    border-width: 40px 40px 0 0;
    border-style: solid;
    border-color: #f0f2f2 #f7f9f9 #f7f9f9 #f0f2f2;
    display: block;
    width: 0; /* Rules for Firefox 3.0 and better */
    }
  }

  &.avatar-top {

    .avatar {
      top: -100%;
      left: 50%;
      transform: translate(-50%, 50%);
      -webkit-transform: translate(-50%, 50%);
      -ms-transform: translate(-50%, 50%);

      @media (max-width: 800px) {
        top: -110px;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
      }

      @media (max-width: 360px) {
        top: -110px;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
      }
    }
  }

  &.quote-left {
    padding: 15px 15px 15px 100px;

    .testimonial-text {
      color: $heading-color;
      margin-bottom: 25px;
    }

    .quote {
      position: absolute;
      top: -20px;
      left: 0;

      i {
        font-size: 90px;
        color: #f0f2f2;
      }
    }
  }
}

.author-info-wrap {
  position: relative;
  z-index: 20;

  .testimonial-img-author {
    float: left;
    margin-right: 30px;
    margin-bottom: 5px;

    &.round {
      border-radius: 100%;
      overflow: hidden;
    }

    &.f-none {
      float: none;
    }
  }

  .author-info {
    .author-position {
      font-size: 14px;
    }
  }

    &.table {
    @media (max-width: 640px) {
      margin-bottom: 30px;
    }
  }
}
