/*------------- #POPUP-SEARCH --------------*/


.popup-search {
  width: auto;
  min-width: 430px;
  padding: 40px 30px 10px 30px;
  border-radius: 5px;
  position: absolute;
  top: 38px;
  right: -20px;
  left: auto;
  background-color: $white-color;
  z-index: 1;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.16);
  display: none;

  @media (max-width: 360px) {
    min-width: 320px;
  }

  .search {
    background-color: $border-color;
    padding: 17px 40px;
    font-size: 16px;
    color: #797676;
    border-color: transparent;
    border-radius: 50px 0 0 50px;
    float: left;
    width: 85%;
  }

  .search-btn {
    display: inline-block;
    background-color: $heading-color;
    padding: 20px 5px;
    border-radius: 0 50px 50px 0;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    color: $white-color;
    width: 60px;
    height: 64px;
  }

  .search-inline {
    position: relative;

    input {
      margin-bottom: 30px;
    }

    .search-btn{
      position: absolute;
      right: 0;
      top:0;

      i {
        margin-left: 0;
      }
    }
  }
}