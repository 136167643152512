/*------------- #BLOCKS --------------*/


@import "../base/variables";
@import "./buttons";
@import "./info-box";
@import "./offers";
@import "./testimonial";
@import "./post";
@import "./stunning-header";
@import "./services";
@import "./popup-search";
@import "./popup-cart";
@import "./pagination";
@import "./navigation";
@import "./socials";
@import "./local-seo";
@import "./accordion";
@import "./pagination-arrow";
@import "./animation-icon";
@import "./input";
@import "./select";
@import "./alerts";
@import "./top-bar";
@import "./range-slider";
@import "./preloader";
@import "./window-popup";
@import "./leaflet";