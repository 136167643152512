/*------------- #Styles for preloader --------------*/


#hellopreloader {
  display: block;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  background: lighten($primary-color, 20%);
  background-size: 41px;
  opacity: 1;
}

.preloader {
  width: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
	position: absolute;
	width: 100%;
	color: $white-color;
	font-size: 14px;
	text-align: center;
  }
}

/*================= Responsive Mode ============*/

@media (max-width: 480px) {
  .preloader {

	.text {
	  font-size: 10px;
	}
  }
}