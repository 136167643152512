/*------------- #POST --------------*/


.post {

  @media (max-width: 360px) {
    margin-bottom: 30px;
  }

  .post__date {
    background-color: #f7f9f9;
    display: block;
    float: left;
  }

  .post__content {
    padding-bottom: 30px;
    border-bottom: 1px solid $border-color;
    clear: both;
    margin-bottom: 30px;

    .post__title {
      font-size: 24px;
      margin-bottom: 15px;
      line-height: 1.25;

      @media (max-width: 800px) {
        font-size: 20px;
      }

      &:hover {
        color: $primary-color;
      }
    }

    .post__text {
        margin-bottom: 0;
    }

    .post__content-info {
      .post-additional-info {
        .category {
          font-size: 14px;
          color: #ccc;
        }

        & > span {
            @media (max-width: 360px) {
              display: block;
            }
        }
      }

      .post-tags {
        padding-top: 30px;
      }
    }

  }

  .post__author {
    display: table;
    font-size: 14px;
    color: #ccc;

    .post-avatar {
      float: left;
      display: table-cell;
      vertical-align: middle;
      margin-right: 15px;
    }

    .post__author-name {
      display: table-cell;
      vertical-align: middle;

      a {
        display: block;
        line-height: 1;
        font-size: 14px;
        color: #2f2c2c;

        @media (max-width: 480px) {
          white-space: nowrap;
        }
      }

      .post__author-link {

        &:hover {
          color: $primary-color;
        }
      }

    }
  }

}

.post__date {
  font-size: 14px;
  padding: 15px 25px;
  border-radius: 50px;
  margin-bottom: 20px;
  display: block;
  color: #ccc;
}

.post-standard {
  padding: 60px 30px 30px;
  background-color: $border-color;
  position: relative;
  margin-bottom: 60px;

  @media (max-width: 480px) {
    padding: 50px 15px 15px;
  }

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }

  &.has-post-thumbnail {
    padding: 30px;

    @media (max-width: 480px) {
      padding: 50px 15px 15px;
    }
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
    .post-thumb {
      .link-image {
        opacity: 1;
        left: 50%;
      }

      .link-post {
        opacity: 1;
        right: 50%;
      }
    }

  }

  .post-thumb {
    position: relative;
    margin-bottom: 60px;
    box-shadow: 24px 50px 60px rgba(0, 0, 0, .3);
    text-align: center;

    @media (max-width: 480px) {
      margin-bottom: 40px;
    }

    .link-image {
      color: $white-color;
      font-size: 36px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(75%, -50%);
      -webkit-transform: translate(75%, -50%);
      -ms-transform: translate(75%, -50%);
      opacity: 0;
      z-index: 50;
      transition: all .6s ease;

      &:hover {
        color: $primary-color;
      }
    }

    .link-post {
      color: $white-color;
      font-size: 36px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-75%, -50%);
      -webkit-transform: translate(-75%, -50%);
      -ms-transform: translate(-75%, -50%);
      opacity: 0;
      z-index: 50;
      transition: all .6s ease;

      &:hover {
        color: $primary-color;
      }
    }

    &.custom-bg {
      background-size: cover;
      background-position: center;
    }

    iframe {
      display: block;
      max-width: 100%;
    }
  }

  .post__content {
    padding-left: 15px;
    padding-bottom: 0;
    margin-bottom: 0;

    .post__author {
      margin-right: 40px;
      float: left;
      text-align: center;

      @media (max-width: 480px) {
        float: none;
        margin-bottom: 20px;
        text-align: left;
      }

      img {
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;

        @media (max-width: 480px) {
          float: left;
          margin-right: 20px;
        }
      }

      .post__author-name {
        display: block;
      }
    }

    .post__content-info {
      overflow: hidden;

      .post__title {
        text-transform: uppercase;
      }

      .post-additional-info {
        margin-bottom: 25px;

        @media (max-width: 480px) {
          margin-bottom: 15px;
        }

        i {
          font-size: 20px;
          margin-right: 10px;
          transition: all .3s ease;
        }
        .post__date {
          padding: 0;
          float: none;
          margin-right: 30px;
          display: inline-block;
          margin-bottom: 0;
        }

        .category {
          margin-right: 30px;

          a {
            color: #ccc;
            display: inline-block;

            &:hover {
              color: $primary-color;
            }
          }
        }

        .post__comments {
          color: #ccc;
          font-size: 14px;

          a {
            color: #ccc;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }

      .post__text {
        font-size: 16px;
        margin-bottom: 30px;

        @media (max-width: 480px) {
          margin-bottom: 20px;
        }
      }

      .btn {
        margin-bottom: 30px;

        &:hover {
          background-color: $primary-color;
        }
      }

      .post-tags {
        padding-top: 30px;
      }
    }
  }

  .overlay {
    opacity: 0;
  }

  &.sticky {
    &:before {
      content: '\e952';
      font-family: "seosight";
      display: block;
      width: 50px;
      height: 50px;
      background-color: #f04e4e;
      line-height: 50px;
      text-align: center;
      color: $white-color;
      font-size: 20px;
      position: absolute;
      top: -20px;
      left: 60px;
      z-index: 5;
    }
  }


  &.video {
    .overlay {
      opacity: 1;
    }

    .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      z-index: 10;
    }
  }


  &.slider {

    &:hover {
      .post-thumb {
        .overlay {
          opacity: 0;
        }
      }
    }

    .post-thumb {
      margin-bottom: 0;
    }

    .post-standard-thumb-slider {
      margin-bottom: 60px;
    }

    .pagination, .swiper-pagination {
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      -ms-transform: translate(-50%, 0);
    }
  }


  &.quote {

    .post-thumb {
      padding: 100px 120px;
      text-align: left;

      @media (max-width: 1024px) {
        padding: 30px;
      }

      .testimonial-content {
        position: relative;
        .text {
          font-size: 24px;
          color: $white-color;
          line-height: 1.4;

          @media (max-width: 570px) {
            font-size: 18px;
          }
        }

        .author-info-wrap {
          .author-info {
            .author-name {
              color: $primary-color;
            }
          }
        }

        .quote {
          position: absolute;
          right: 0;
          bottom: -30px;

          @media (max-width: 360px) {
            display: none;
          }

          i {
            font-size: 140px;
            color: #fcd846;
          }
        }
      }
    }
  }

  &.audio {
    .post-thumb {
      height: auto;
      overflow: hidden;

      @media (max-width: 360px) {
        height: auto;
      }

      .author-photo {
        position: relative;
        float: left;
        z-index: 5;

        @media (max-width: 360px) {
          float: none;
        }

        .overlay-audio {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(43, 48, 68, .8);
        }

        .play-audio {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);

          i {
            font-size: 46px;
            color: $white-color;
          }
        }
      }

      .audio-player {
        position: relative;
        padding: 40px;
        background-color: #31364c;
        margin-left: 165px;
        height: 100%;

        @media (max-width: 360px) {
          margin-left: 0;
        }

        .composition-time {
          text-align: right;
          font-size: 12px;
          margin-bottom: 4px;

          @media (max-width: 980px) {
            text-align: left;
          }

          @media (max-width: 768px) {
            text-align: right;
          }

          .time-over {
            color: $primary-color;
            margin-right: 20px;
          }

          .time-total {
            color: #6b7186;
          }
        }

        .play-meter {
          width: 100%;
          border-radius: 10px;
          background-color: #3d4359;
          position: relative;
          height: 8px;

          .play-meter-active {
            position: relative;
            height: 8px;
            display: inline-block;
            border-radius: 5px;
            bottom: 9px;

            &:after {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              border: 5px solid;
              border-color: inherit;
              border-radius: 100%;
              position: absolute;
              right: -17px;
              top: 50%;
              transform: translate(0, -50%);
              -webkit-transform: translate(0, -50%);
              -ms-transform: translate(0, -50%);
            }
          }
        }

        .composition {
          display: inline-block;

          .composition-title {
            color: $white-color;
            line-height: 1;
          }

          .composition-subtitle {
            color: #6b7186;
            margin-bottom: 0;
          }
        }

        .like-share {
          float: right;

          @media (max-width: 480px) {
            float: none;
          }

          a {
            margin-right: 20px;

            &:last-child {
              margin-right: 0;
            }

            i {
              font-size: 18px;
              color: #6b7186;

              &.red {
                color: #f04e4e;
              }
            }
          }
        }

      }
    }
  }

  &.link {

    .post-thumb {
      padding: 120px 100px;
      text-align: left;

      @media (max-width: 1024px) {
        padding: 80px;
      }

      @media (max-width: 570px) {
        padding: 30px;
      }

      .thumb-content {
        position: relative;
        z-index: 50;

        .thumb-content-title {
          color: $white-color;
          margin-bottom: 30px;
          display: block;
        }

        .site-link {
          color: #11847f;
          display: block;
        }

        .post-link {
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;

          i {
            font-size: 75px;
            color: #fff568;
          }
        }
      }
    }
  }
}


/*------------- #Post-standard-details --------------*/


.post-standard-details {
  margin-bottom: 80px;

  .post-thumb {
    box-shadow: 24px 50px 60px rgba(0, 0, 0, .3);
    margin-bottom: 60px;
    float: left;
  }

  .post__content {
    margin-bottom: 30px;

    .post__text {
      margin-bottom: 30px;
    }

    .post__title {
      text-transform: uppercase;
      margin-bottom: 30px;
    }

    .post-additional-info {
      margin-bottom: 30px;

      > span {
        @media (max-width: 768px) {
          display: block;
          margin-bottom: 10px;
        }
      }

      i {
        font-size: 20px;
        margin-right: 10px;
        transition: all .3s ease;
        color: #ccc;
      }

      .post__author {
        display: inline-block;
        margin-right: 30px;

        @media (max-width: 768px) {
          display: block;
          margin-bottom: 10px;
        }

        img {
          margin-right: 10px;
        }

        .post__author-name {
          display: inline-block;
        }
      }

      .post__date {
        padding: 0;
        float: none;
        margin-right: 30px;
        display: inline-block;
        margin-bottom: 0;
        background-color: transparent;

        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }

      .category {
        margin-right: 30px;

        a {
          color: #ccc;
          display: inline-block;
          font-size: 14px;

          &:hover {
            color: $primary-color;
          }
        }
      }

      .post__comments {
        color: #ccc;
        font-size: 14px;

        a {
          color: #ccc;
          font-size: 14px;
        }

        &:hover {
          color: $primary-color;
        }
      }
    }

    .post__subtitle {
      color: $heading-color;
      margin-bottom: 30px;
    }


    .testimonial-item.quote-left {
      margin: 60px 0;

      @media (max-width: 768px) {
        margin: 30px 0;
      }
    }

    .list {
      margin-bottom: 30px;
    }

    .w-tags {
      margin-bottom: 10px;
    }
  }

  .socials {

    .social__item {
      i {
        font-size: 20px;
        color: #d7d7d7;
        transition: all .3s ease;
      }

      &:hover {
        i {
          color: #08acee;
        }
      }
    }

    .social__item:first-child {
      margin-left: 15px;
    }

    button {
      background: none;
      cursor: pointer;
      margin-left: 0;
    }

    .label {
      position: relative;
      top: -10px;
    }
  }

}

.blog-details-author {
  padding: 60px;
  background-color: $border-color;
  margin-bottom: 60px;

  .blog-details-author-thumb {
    float: left;
    margin-right: 30px;

    @media (max-width: 480px) {
      float: none;
      margin-bottom: 30px;
    }
  }

  .blog-details-author-content {
    overflow: hidden;

    .author-info {
      margin-bottom: 0;

      .author-name {
        display: inline-block;
        margin-right: 30px;
      }

      .author-info {
        display: inline-block;
        font-size: 14px;
      }
    }

    .text {
      margin-bottom: 20px;
    }
  }

  .socials {
    .social__item {
      img {
        width: 20px;
        height: auto;
      }
    }
  }
}

body.author .blog-details-author {
  margin-bottom: 30px;
}

.author .avatar {
  border-radius: 100%;
}